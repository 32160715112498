@import 'config';
@import '~basement/src/index';
@import 'settings', 'fontface', 'reset', 'utils', 'global', 'typography';
@import 'components';
@import 'views';

[data-whatintent='mouse'] *:focus {
  outline: none;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  @media (query(md-up)) {
    min-height: 100vh;
  }
}

.SiteWideBanner {
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.Main {
  position: relative;
}

.ContentBlocks {
  position: relative;
}

.ContentBlock {
  position: relative;
  width: 100vw;
  height: 100%;
  overflow: hidden;

  .content-block-title {
    color: inherit;
    font-family: $futura-pt;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    padding: 2rem 1rem 2.5rem 1rem;

    @media (min-width: 750px) {
      width: 70vw;
      text-wrap: balance;
      font-size: 2.125rem;
      letter-spacing: 0.02125rem;
      padding: 2rem;
    }

    @media (min-width: 2000px) {
      font-size: 3.125rem;
      letter-spacing: 0.0625rem;
      padding: 3rem;
    }
  }

  .content-block-spacer {
    display: block;
    width: 100%;
    height: 30vh;
  }

  .border-top {
    border-top: 1px solid color('gray');
    width: auto;
    margin: 0 1rem;

    @media (min-width: 750px) {
      margin: 0 2rem;
    }

    @media (min-width: 2000px) {
      margin: 0 3rem;
    }
  }
}
