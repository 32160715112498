.BaseButton {
  position: relative;
  pointer-events: all;
  transition: $transition-medium;
}

.PrimaryButton {
  display: inline-flex;
  padding: 0.5rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;

  border: 1px solid color('white');
  background-color: color('white');
  color: color('medium-gray');
  font-size: 1rem;
  font-style: normal;
  line-height: 140%;
  text-decoration: none;

  cursor: pointer;

  &:hover {
    border-color: color('medium-gray');
    color: color('black');
  }

  @media (max-width: 749px) {
    padding: .25rem .75rem;
  }
}

.SecondaryButton {
  display: inline-flex;
  padding: 0.5rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;

  border: 1px solid color('medium-gray-opacity-04');
  color: color('medium-gray');
  font-size: 1rem;
  font-style: normal;
  line-height: 140%;
  text-decoration: none;

  cursor: pointer;

  &:hover {
    border-color: color('medium-gray');
  }

  @media (max-width: 749px) {
    padding: .25rem .75rem;
  }
}

.SecondaryButton__darkMode {
  display: inline-flex;
  padding: 0.5rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;

  border: 1px solid color('white-opacity-04');
  color: color('white');
  text-decoration: none;

  cursor: pointer;

  &:hover {
    border-color: color('white');
  }

  @media (max-width: 749px) {
    padding: .25rem .75rem;
  }
}