$colors: (
  'black': #222222,
  'black-opacity-02': rgba(34, 34, 34, 0.2),
  'dark-gray': #171614,
  'medium-gray': #555555,
  'medium-gray-opacity-04': rgba(85, 85, 85, 0.4),
  'dust-gray': #7a7a77,
  'cloud-gray': #a9a8a4,
  'gray': #c6c5c2,
  'gray-opacity-02': rgba(198, 197, 193, 0.2),
  'light-gray': #d8d8d8,
  'lightest-gray': #EDEDED,
  'white': #fff,
  'white-opacity-02': rgba(255, 255, 255, 0.2),
  'white-opacity-04': rgba(255, 255, 255, 0.4),
  'red': #ca6767,
  'error-red': red
);

@each $name, $text-decoration-color in $colors {
  .text-decoration-color-#{$name} {
    text-decoration-color: #{$text-decoration-color};
  }
}