.image-block {
  .image-block__image-wrapper {
    padding: 5rem 0;

    @media (min-width: 750px) {
      padding: 10rem 0;
    }

    .image-block__image {
      display: block;
      width: 9rem;
      height: auto;
      margin: 0 auto;

      @media (min-width: 750px) {
        width: 16rem;
      }
    }
  }
}