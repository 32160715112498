.accordion-block {
  width: 100vw;
  height: 100%;

  .body {
    display: flex;
    flex-direction: column;
    padding-bottom: 1.5rem;
    position: relative;
    z-index: z('above_2');
  }
}