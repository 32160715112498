.tab-wrapper {
  display: flex;
  flex-direction: column;
  flex: 0;
  padding: 0 1.5rem;
  transition: height 1s ease;

  @media (min-width: 750px) {
    width: 100%;
    max-width: 95%;
    margin: 0 auto;
    flex-direction: row;
    align-items: center;
    padding: 0;
  }

  .empty-container {
    display: none;
    width: 100%;

    @media (min-width: 1130px) {
      display: block;
    }
  }

  .tab-image-container-layout-a {
    width: 100%;
    height: 40dvh;
    display: flex;
    justify-content: center;

    @media (min-width: 750px) {
      width: 1200px;
      height: auto;
    }

    .tab-image-wrapper {
      margin: 0 auto;
      height: 100%;

      &__inactive {
        display: none;
      }

      &__active {
        display: block;
      }

      @media (min-width: 750px) {
        width: 100%;
        height: auto;
      }
  
      .tab-image {
        width: auto;
        height: 100%;
  
        @media (min-width: 750px) {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .tab-image-wrapper-layout-b {
    width: 0;
    margin: 0 auto;
    height: 327px;
    position: relative;

    @media (max-width: 749px) {
      height: 500px;
    }

    @media (max-width: 600px) {
      height: 340px;
    }

    @media (max-width: 374px) {
      height: 250px;
    }

    .tab-image-container-layout-b {
      height: 100%;
      display: flex;
      position: absolute;
      transition: 0.2s ease-in-out;
      top: 0;
      left: 0;
  
      .tab-image-spacer {
        flex: 0 0 auto;
        width: 327px;
        height: 327px;

        @media (max-width: 749px) {
          width: 500px;
          height: 500px;
        }

        @media (max-width: 600px) {
          width: 340px;
          height: 340px;
        }

        @media (max-width: 374px) {
          width: 250px;
          height: 250px;
        }
      }
  
      .tab-image-wrapper {
        transform: translateX(-50%);
        width: auto;
        height: 327px;
        margin: 0 auto;
        transition: 0.2s ease-in-out;
  
        &__inactive {
        }
  
        &__active {
        }
    
        .tab-image {
          width: auto;
          height: 100%;
        }

        @media (max-width: 749px) {
          height: 500px;
        }

        @media (max-width: 600px) {
          height: 340px;
        }

        @media (max-width: 374px) {
          height: 250px;
        }
      }
    }
  }
  

  .pagination-wrapper {
    display: flex;
    flex-direction: row;
    gap: .55rem;
    width: 100%;
    height: 10px;
    margin-top: 2rem;

    @media (min-width: 750px) {
      display: none;
    }

    .pagination-button {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      background-color: transparent;
      padding: 0;
      cursor: pointer;

      .pagination-item {
        display: block;
        height: 2px;
        width: 100%;
  
        &__inactive {
          opacity: 0.2;
        }
  
        &__active {
          opacity: 1;
        }
      }
    }
  }

  .tabs {
    width: 100%;

    .tablist {
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin: 24px 0;
      transition: 1s ease-in-out;

      @media (min-width: 750px) {
        width: fit-content;
        margin: 0 auto;
        max-width: 328px;
      }

      .tab-item {
        transition: 0.2s;

        @media (min-width: 750px) {
          padding-left: 26px;
        }

        &__inactive {
          opacity: 0.5;
          border-left: 2px solid transparent;

          &:hover {
            opacity: 1;
          }

          @media (max-width: 749px) {
            display: none;
          }
        }
      
        &__active {
          border-left: 2px solid color('white');
          margin-bottom: 20px;

          @media (max-width: 749px) {
            display: block;
            border: none;
          }
        }
      }

      .tab-item-title {
        color: inherit;
        font-family: $futura-pt;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.0225rem;
        margin-bottom: 1rem;
        padding: 0;
        text-align: left;
        white-space: wrap;
        background-color: transparent;
        cursor: pointer;

        @media (min-width: 750px) {
          font-size: 1.375rem;
          letter-spacing: 0.44px;
        }
      }

      .tab-item-content {
        display: block;
        font-family: $akkurat;
        font-size: 0.9375rem;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        transition: 0.2s ease;

        @media (min-width: 750px) {
          font-size: 1rem;
        }

        &__inactive {
          @media (min-width: 750px) {
            overflow: hidden;
            transition: all 0.5s ease;
            transform: translateY(-10%);
            max-height: 0;
            opacity: 0;
          }
        }
      
        &__active {
          @media (min-width: 750px) {
            transform: translateY(0);
            max-height: none;
            opacity: 1;
          }
        }
      }

      .tab-ctas {
        display: flex;
        flex-direction: row;
        gap: 15px;
        margin-top: 22px;

        @media (min-width: 750px) {
          margin-top: 34px;
        }

        .tab-item-cta {
          width: 100%;
        }
      }

      .tab-cta-wrapper {

        @media (min-width: 750px) {
          margin-top: 15px;
          padding-left: 26px;
        }
    
        a {
          color: inherit;
          text-decoration: none;
        }
      
        .tab-cta {
          width: 100%;
          text-align: center;
          font-family: $akkurat;
          font-weight: 400;
          font-size: 1rem;
          font-style: normal;
          line-height: 140%;

          @media (min-width: 750px) {
            width: fit-content;
          }
        }
      }
    }
  }
}